// application list modify routes
export const applicationList = '/scheme-application/list'
export const getOfficeUser = '/user/current-office-user'
export const getUsers = '/user/user-list'
export const applicationShow = '/scheme-application/show'
export const applicationStatusUpdate = '/scheme-application/status-update'
export const schemeAssignProject = '/scheme-project/store'
export const schemeReviewStore = '/scheme-review/store'
export const schemeRejectStore = '/scheme-reject/store'
export const schemeAppSend = '/scheme-application/send'
export const schemeAppLog = '/scheme-application/log'
export const schemeSurveyStore = '/scheme-survey/store'
export const getSchemeSurveyReport = '/scheme-survey/list'
export const getSchemeNote = '/scheme-note/list'
export const getLoggedUserSupervisor = '/user/supervisor'

// Farmer Scheme Report routes
export const irrigationFarmerList = '/irrigation-farmer-list'
export const farmerSchemeReportList = '/farmer-scheme/report'

// receive scheme application list
export const receiveApplicationList = '/scheme-application/receive-application-list'

// license license application
export const licenseApplicationList = '/scheme-application/license-application-list'
export const licenseApplicationStatusUpdate = '/scheme-application/license-status-update'

// current status license application
export const currentStatusApplicationList = '/scheme-application/current-status-application-list'
export const farSchLicenseStore = '/farmer-scheme-license/store'
export const farSchLicenseShow = '/farmer-scheme-license/show'
export const farSchLicenseVerify = '/farmer-scheme-license/verify'

// contract agreement application
export const participationFeeApplication = '/scheme-application/participation-fee-application'
export const participationFeeStore = '/scheme-participation-fee/store'
export const getParticipationCusec = '/scheme-participation-fee/participation-cusec'
export const getMasterPaymentList = '/scheme-participation-fee/get-master-payment-list'
export const getSecurityMoneyCusec = '/scheme-participation-fee/security-money-cusec'
export const getParticipationFee = '/scheme-participation-fee/details'

// contract agreement application
export const contactAgreementApplicationList = '/scheme-application/contract-agreement-application-list'
export const contactAgreementStore = '/contract-agreement/store'
export const contactAgreementDocumentStore = '/contract-agreement/document/store'
// stock
export const stockInList = '/pump-stock-in-infos/list'
export const stockInStore = '/pump-stock-in-infos/store'
// received requistion
export const receivedRequisitionList = '/scheme-requisition/receive-requisition-list'
export const receivedRequisition = '/scheme-requisition/receive-requisition'
// requistion
export const agreementApplicationList = '/scheme-application/agreement-application-list'
export const getRequisitionIdUrl = '/scheme-requisition/requisition-id'
export const requisitionStore = '/scheme-requisition/store'
export const requisitionDetails = '/scheme-requisition/details'
export const requisitionApprove = '/scheme-requisition/approve'
export const requisitionQuantityUpdate = '/scheme-requisition/quantity/update'
export const requisitionForwardAllocate = '/scheme-requisition/forward-allocate'
export const stockOutList = '/pump-stock-out-infos/list'
export const stockOutStore = '/pump-stock-out-infos/store'
export const stockOutApprove = '/pump-stock-out-infos/approvel'
export const stockOutToogleStatus = '/pump-stock-out-infos/toogle-status'
export const stockInToogleStatus = '/pump-stock-in-infos/toggle-status'
// supply equipment
export const requisitionList = '/scheme-application/requisition-list'
export const supplyEquipmentStore = '/supply-equipment/store'
export const supplyEquipmentDetails = '/supply-equipment/details'
// pump opt application
export const pumpOptApplicationList = '/farmer-pump-operator-application/list'
export const pumpOptApplicationListReceive = '/farmer-pump-operator-application/receive-pump-opt-list'
export const pumpOptApplicationListSurvey = '/farmer-pump-operator-application/survey-pump-opt-list'
export const pumpOptRenewApplicationList = '/farmer-pump-operator-application/renew-list'
export const pumpOptApplicationDetailsList = 'farmer-pump-operator-application/application-details'
export const pumpOptApplicationRejectStore = '/pump-operator-rejects/store'
export const pumpOptApplicationApprove = '/farmer-pump-operator-application/approved'
export const currentOfficeUser = '/user/current-office-user'
export const pumpOptAppSend = '/farmer-pump-operator-application/send'
export const pumpOptAppSendForSurvey = '/farmer-pump-operator-application/send-for-survey'
export const pumpOptAppReceive = '/farmer-pump-operator-application/receive'
export const pumpOptAppAddSurvey = '/farmer-pump-operator-application/add-survey'
export const pumpOptAppSendSurveyNote = '/farmer-pump-operator-application/send-survey-note'
// pump install
export const pumpInstallList = '/scheme-application/pump-install-list'
export const masterPumpInstallProgressTypes = '/pump-install/progress-types'
export const getContractor = '/pump-install/contractors'
export const pumpInstallProgressUpdate = '/pump-install/progress-update'
// dashboard
export const pumpInstallDashboardOverview = '/pump-install-dashboard/fetch/overview'
// pump installation (Pump Report)
export const pumpInstallPumpOperatorReport = '/pump-operator/report'
// pump agreement pdf Route
export const agreementPdf = '/contract-agreement/agreementPdf'
// scheme-farmer-land-details
const schemeFarLandDetails = 'farmer-land-details'
export const schemeFarList = schemeFarLandDetails + '/list'
export const updateFarmer = '/scheme-application/update-farmer'
export const removeFarmer = '/scheme-application/remove-farmer'

// Nothi api
export const nothi = 'nothi/'
export const nothiSend = nothi + 'scheme/send'
export const nothiReSend = nothi + 'scheme/re-send'
export const pumpOptNothiSend = nothi + 'pump-opt/send'
export const pumpOptNothiReSend = nothi + 'pump-opt/re-send'
