import i18n from '@/i18n'
import Store from '@/store'
import { irriSchemeServiceBaseUrl } from '@/config/api_config'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

function getBase64ImageFromURL (url) {
  return new Promise((resolve, reject) => {
    var img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')

    img.onload = () => {
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      var dataURL = canvas.toDataURL('image/png')

      resolve(dataURL)
    }

    img.onerror = error => {
      reject(error)
    }

    img.src = url
  })
}

const exportPdfDetails = async (reportTitle, thisObject, data) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }

    const pdfContent = [
      { text: thisObject.$t('pump_install.pump_install_application_details'), style: 'header2', alignment: 'center' }
    ]

    const applicationTopRowsHead = []
    if (data.sch_man_photo !== null) {
      applicationTopRowsHead.push([
        { text: thisObject.$t('pump_install.application_id'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n(data.application_id, { useGrouping: false }), alignment: 'left', style: 'search' },
        { image: data.sch_man_photo !== null ? await getBase64ImageFromURL(irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/scheme-application/scheme-manager-photo/original/' + data.sch_man_photo) : '', width: 100, height: 100, style: 'search', alignment: 'right', rowSpan: 5 }
      ])
    } else {
      applicationTopRowsHead.push([
        { text: thisObject.$t('pump_install.application_id'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n(data.application_id, { useGrouping: false }), alignment: 'left', style: 'search' },
        { text: 'Profile Photo Not Found', style: 'search', alignment: 'right', rowSpan: 5 }
      ])
    }

  applicationTopRowsHead.push([
    { text: thisObject.$t('globalTrans.date'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: dateFormat(data.created_at), alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.organization'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: i18n.locale === 'en' ? data.org_name : data.org_name_bn, alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('globalTrans.address'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: (thisObject.$t(data.pump_mauza_no) + ', ' + thisObject.$n(data.pump_jl_no, { useGrouping: false }) + ', ' + thisObject.$n(data.pump_plot_no, { useGrouping: false }) + ', ' + data.unionName + ', ' + data.upazilaName + ', ' + data.districtName + ', ' + data.divisionName), alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.dear_sir') + ',', alignment: 'left', style: 'search', colSpan: 3 },
    {},
    {},
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.i_am_writing'), alignment: 'justify', style: 'search', colSpan: 4 },
    {},
    {},
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.i_would_appreciate'), alignment: 'justify', style: 'search', colSpan: 4 },
    {},
    {},
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.sincerely') + ',', alignment: 'left', style: 'search', colSpan: 4 },
    {},
    {},
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.father_name'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: i18n.locale === 'en' ? data.father_name : data.father_name_bn, alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.no_of_water_user'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: thisObject.$n(data.total_farmer), alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.mobile'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: thisObject.$n('0') + thisObject.$n(data.far_mobile_no, { useGrouping: false }), alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.email'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: data.email, alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.this_is_computer_generated'), alignment: 'center', style: 'search', colSpan: 4 },
    {},
    {},
    {}
  ])

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['15%', '2%', '50%', '30%'],
        body: applicationTopRowsHead
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    // Application Details
    pdfContent.push([
      { text: reportTitle, style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const allRowsHead = [
        [
        { text: thisObject.$t('pump_install.application_id'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n(data.application_id, { useGrouping: false }), alignment: 'left', style: 'search' },
        { text: thisObject.$t('pump_install.name'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.name : data.name_bn, alignment: 'left', style: 'search' }
      ]
    ]

    allRowsHead.push([
      { text: thisObject.$t('pump_install.father_name'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.father_name : data.father_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.mother_name'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.mother_name : data.mother_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.nid'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.nid, { useGrouping: false }), alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.mobile'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n('0') + thisObject.$n(data.far_mobile_no, { useGrouping: false }), alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.division'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: data.divisionName, alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.district'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.districtName : data.districtName, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.upazila'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: data.upazilaName, alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.union'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.unionName : data.unionName, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.village'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.far_village : data.far_village_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.organization'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.org_name : data.org_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.irrigation_type'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.scheme_type_name : data.scheme_type_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('irrigation_config.sub_scheme_type_list'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.sub_scheme_name : data.sub_scheme_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irrigation_config.pump_capacity'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.pump_capacity : data.pump_capacity_bn, alignment: 'left', style: 'search' },
      {},
      {},
      {}
    ])

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })

      pdfContent.push([
        { text: thisObject.$t('pump_install.scheme_man_details'), style: 'header3', alignment: 'center', decoration: 'underline' }
      ])

      const allRowsHeadSchemeDetails = [
        [
          { text: thisObject.$t('pump_install.name'), alignment: 'right', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: i18n.locale === 'en' ? data.sch_man_name : data.sch_man_name_bn, alignment: 'left', style: 'search' },
          { text: thisObject.$t('pump_install.email'), alignment: 'right', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: data.email, alignment: 'left', style: 'search' }
        ]
      ]

      allRowsHeadSchemeDetails.push([
        { text: thisObject.$t('pump_install.mobile'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n('0') + thisObject.$n(data.sch_man_mobile_no, { useGrouping: false }), alignment: 'left', style: 'search' },
        { text: thisObject.$t('pump_install.nid'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n(data.sch_man_nid, { useGrouping: false }), alignment: 'left', style: 'search' }
      ])

      allRowsHeadSchemeDetails.push([
        { text: thisObject.$t('pump_install.father_name'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.sch_man_father_name : data.sch_man_father_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('pump_install.mother_name'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.sch_man_mother_name : data.sch_man_mother_name_bn, alignment: 'left', style: 'search' }
      ])

      allRowsHeadSchemeDetails.push([
        { text: thisObject.$t('pump_install.division'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.divisionName : data.divisionName, alignment: 'left', style: 'search' },
        { text: thisObject.$t('pump_install.district'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.districtName : data.districtName, alignment: 'left', style: 'search' }
      ])

      allRowsHeadSchemeDetails.push([
        { text: thisObject.$t('pump_install.upazila'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.upazilaName : data.upazilaName, alignment: 'left', style: 'search' },
        { text: thisObject.$t('pump_install.union'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.unionName : data.unionName, alignment: 'left', style: 'search' }
      ])

      allRowsHeadSchemeDetails.push([
        { text: thisObject.$t('pump_install.village'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.sch_man_village : data.sch_man_village_bn, alignment: 'left', style: 'search' },
        {},
        {},
        {}
      ])

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHeadSchemeDetails
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })

      // Pump Info detials
      pdfContent.push([
        { text: thisObject.$t('pump_install.pump_details'), style: 'header3', alignment: 'center', decoration: 'underline' }
      ])

      var allRowsHeadPumpInfoDetails = []

      if (data.status === 14) {
        allRowsHeadPumpInfoDetails.push([
            { text: thisObject.$t('pump_install.pump_id'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: data.pump_id, alignment: 'left', style: 'search' },
            { text: thisObject.$t('pump_install.installed_date'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: dateFormat(data.updated_at), alignment: 'left', style: 'search' }
        ])
      }

      allRowsHeadPumpInfoDetails.push([
          { text: thisObject.$t('pump_install.division'), alignment: 'right', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: i18n.locale === 'en' ? data.divisionName : data.divisionName, alignment: 'left', style: 'search' },
          { text: thisObject.$t('pump_install.district'), alignment: 'right', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: i18n.locale === 'en' ? data.districtName : data.districtName, alignment: 'left', style: 'search' }
      ])

      allRowsHeadPumpInfoDetails.push([
        { text: thisObject.$t('pump_install.upazila'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.upazilaName : data.upazilaName, alignment: 'left', style: 'search' },
        { text: thisObject.$t('pump_install.union'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.unionName : data.unionName, alignment: 'left', style: 'search' }
      ])

      allRowsHeadPumpInfoDetails.push([
        { text: thisObject.$t('pump_install.mauza_no'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n(data.pump_mauza_no, { useGrouping: false }), alignment: 'left', style: 'search' },
        { text: thisObject.$t('pump_install.pump_jl_no'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n(data.pump_jl_no, { useGrouping: false }), alignment: 'left', style: 'search' }
      ])

      allRowsHeadPumpInfoDetails.push([
        { text: thisObject.$t('pump_install.plot_no'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n(data.pump_plot_no, { useGrouping: false }), alignment: 'left', style: 'search' },
        {},
        {},
        {}
      ])

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHeadPumpInfoDetails
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })

      if (data.project_id !== null) {
        // Pump Info project
        pdfContent.push([
          { text: thisObject.$t('pump_install.project'), style: 'header3', alignment: 'center', decoration: 'underline' }
        ])

        const allRowsHeadProject = [
          [
            { text: thisObject.$t('pump_install.project'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.project_name : data.project_name_bn, alignment: 'left', style: 'search' }
          ]
        ]

        pdfContent.push({
          table: {
            headerRows: 0,
            widths: ['40%', '5%', '40%'],
            body: allRowsHeadProject
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
      }

      if (data.survey_id !== null) {
        // Pump Info survey
        pdfContent.push([
          { text: thisObject.$t('pump_install.survey'), style: 'header3', alignment: 'center', decoration: 'underline' }
        ])

        const allRowsHeadSurvey = [
          [
            { text: thisObject.$t('pump_install.survey_date'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: data.survey_date !== null ? dateFormat(data.survey_date) : '', alignment: 'left', style: 'search' },
            { text: thisObject.$t('pump_install.suggestion'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.suggestion : data.suggestion_bn, alignment: 'left', style: 'search' }
          ]
        ]

        pdfContent.push({
          table: {
            headerRows: 0,
            widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
            body: allRowsHeadSurvey
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
      }

      if (data.license_id !== null) {
        // license Info
        pdfContent.push([
          { text: thisObject.$t('pump_install.license'), style: 'header3', alignment: 'center', decoration: 'underline' }
        ])

        const allRowsHeadLicense = [
          [
            { text: thisObject.$t('pump_install.license_no'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: data.license_no, alignment: 'left', style: 'search' },
            { text: thisObject.$t('pump_install.issue_date'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: data.license_issue_date !== null ? dateFormat(data.license_issue_date) : '', alignment: 'left', style: 'search' }
          ]
        ]

        pdfContent.push({
          table: {
            headerRows: 0,
            widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
            body: allRowsHeadLicense
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
      }

      if (data.survey_notes.length > 0) {
        // Survery Note
        pdfContent.push({ text: thisObject.$t('pump_install.survey_note'), style: 'surveyNoteStyle', alignment: 'center', decoration: 'underline' })
        const headList = [
          { text: thisObject.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.notel'), style: 'th', alignment: 'center' }
        ]

        const allRowsSurveyNote = [headList]
        data.survey_notes.map((item, index) => {
          const newRow = [
            { text: thisObject.$n(index + 1), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'en') ? item.note : item.note_bn, style: 'td', alignment: 'center' }
          ]
          allRowsSurveyNote.push(newRow)
        })

        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['12%', '*'],
            body: allRowsSurveyNote
          }
        })
      }

      if (data.progress_types.length > 0 && data.progress_types[0].far_pump_install.length > 0) {
        // Progress Types
        pdfContent.push({ text: thisObject.$t('pump_install.pump_installation_progress'), style: 'header3', alignment: 'center', decoration: 'underline' })
        const progressTypesHeadList = [
          { text: thisObject.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.step_name'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.start_date'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.end_date'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.notel'), style: 'th', alignment: 'center' }
        ]

        const allRowsProgressTypes = [progressTypesHeadList]
        data.progress_types.map((item, index) => {
          const newRow = [
            { text: thisObject.$n(index + 1), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'en') ? item.step_name : item.step_name_bn, style: 'td', alignment: 'center' },
            { text: item.far_pump_install[0] !== undefined ? dateFormat(item.far_pump_install[0].start_date) : '', style: 'td', alignment: 'center' },
            { text: item.far_pump_install[0] !== undefined ? dateFormat(item.far_pump_install[0].end_date) : '', style: 'td', alignment: 'center' },
            { text: item.far_pump_install[0] !== undefined ? (i18n.locale === 'en') ? item.far_pump_install[0].note : item.far_pump_install[0].note_bn : '', style: 'td', alignment: 'center' }
          ]
          allRowsProgressTypes.push(newRow)
        })

        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['12%', '*', '*', '*', '*'],
            body: allRowsProgressTypes
          }
        })
      }

        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: i18n.locale === 'bn' ? 14 : 12,
            margin: [0, 10, 0, 10]
          },
          header3: {
            fontSize: i18n.locale === 'bn' ? 13 : 11,
            margin: [0, 10, 0, 5]
          },
          surveyNoteStyle: {
            fontSize: i18n.locale === 'bn' ? 13 : 11,
            margin: [0, 50, 0, 5]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
