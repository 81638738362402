<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.print') }}
        </b-button>
        <b-col sm="12">
          <iq-card style="padding: 20px;">
            <template>
              <b-row>
                <b-col sm="8">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 20%;">{{ $t('pump_install.application_id') }}</th>
                      <td style="width: 5%;">:</td>
                      <td style="width: 75%;border-bottom: 1px dashed #999;">{{ application.application_id }}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%;">{{ $t('globalTrans.date') }}</th>
                      <td style="width: 5%;">:</td>
                      <td style="width: 75%;border-bottom: 1px dashed #999;">{{ application.created_at | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%;">{{ $t('pump_install.organization') }}</th>
                      <td style="width: 5%;">:</td>
                      <td style="width: 75%;border-bottom: 1px dashed #999;">{{ getOrganization(application.org_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%;">{{ $t('globalTrans.address') }}</th>
                      <td style="width: 5%;">:</td>
                      <td style="width: 75%;border-bottom: 1px dashed #999;">{{ getPumpAddress(application) }}</td>
                    </tr>
                  </table>
                </b-col>
                <b-col sm="4">
                  <img :src="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-manager-photo/original/' + application.sch_man_photo" :alt="application.name" class="img-fluid" style="border: 1px solid #999;float: right;width: 170px;height: 180px;"/>
                </b-col>
                <b-col sm="12" class="my-3">
                  <p class="mb-1">{{ $t('pump_install.dear_sir') }},</p>
                  <p class="mb-1">{{ $t('pump_install.i_am_writing') }}</p>
                  <p>{{ $t('pump_install.i_would_appreciate') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 13%;">{{ $t('pump_install.sincerely') }}</th>
                      <td style="width: 2%;"></td>
                      <td style="width: 85%;"></td>
                    </tr>
                    <tr>
                      <th style="width: 13%;">{{ $t('pump_install.father_name') }}</th>
                      <td style="width: 2%;">:</td>
                      <td style="width: 85%;">{{ ($i18n.locale == 'bn') ? application.father_name_bn : application.father_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 13%;">{{ $t('pump_install.no_of_water_user') }}</th>
                      <td style="width: 2%;">:</td>
                      <td style="width: 85%;">{{ application.total_farmer }}</td>
                    </tr>
                    <tr>
                      <th style="width: 13%;">{{ $t('pump_install.mobile') }}</th>
                      <td style="width: 2%;">:</td>
                      <td style="width: 85%;">{{ $n(0) + $n(application.far_mobile_no, { useGrouping: false }) }}</td>
                    </tr>
                  </table>
                </b-col>
                <b-col sm="12">
                  <p class="text-center mt-1 mb-4">[{{ $t('pump_install.this_is_computer_generated') }}]</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.application_details') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="6">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.application_id') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ $n(application.application_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.name') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.nid') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.nid }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('irrigation_config.sub_scheme_type_list') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ getSubSchemeType(application.sub_scheme_type_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.father_name') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.father_name_bn : application.father_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.division') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ appHierarchyNames.divisionName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.upazila') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ appHierarchyNames.upazilaName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.village') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.far_village_bn : application.far_village }}</td>
                    </tr>
                  </table>
                </b-col>
                <b-col sm="6">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.organization') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ getOrganization(application.org_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.irrigation_type') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.scheme_type_name_bn : application.scheme_type_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('irrigation_config.pump_capacity') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ getPumpCapacity(application.pump_capacity_id) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.mother_name') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.mother_name_bn : application.mother_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.district') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ appHierarchyNames.districtName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.union') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ appHierarchyNames.unionName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.mobile') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.far_mobile_no | mobileNumber }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.scheme_man_details') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="6">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.name') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ (this.$i18n.locale === 'bn') ? application.sch_man_name_bn : application.sch_man_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.mobile') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.sch_man_mobile_no | mobileNumber }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.father_name') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.sch_man_father_name_bn : application.sch_man_father_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.division') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ schemeHierarchyNames.divisionName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.upazila') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ schemeHierarchyNames.upazilaName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.village') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.sch_man_village_bn : application.sch_man_village }}</td>
                    </tr>
                  </table>
                </b-col>
                <b-col sm="6">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.email') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.email }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.nid') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.sch_man_nid }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.mother_name') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.sch_man_mother_name_bn : application.sch_man_mother_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.district') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ schemeHierarchyNames.districtName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.union') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ schemeHierarchyNames.unionName }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.pump_details') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="6">
                  <table class="table table-sm table-borderless">
                    <tr v-if="application.status == 14">
                      <th style="width: 30%;">{{ $t('pump_install.pump_id') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.pump_id }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.division') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ schemeHierarchyNames.divisionName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.upazila') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ schemeHierarchyNames.upazilaName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.mauza_no') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.pump_mauza_no_bn : application.pump_mauza_no }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.plot_no') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.pump_plot_no_bn : application.pump_plot_no }}</td>
                    </tr>
                  </table>
                </b-col>
                <b-col sm="6">
                  <table class="table table-sm table-borderless">
                    <tr v-if="application.status == 14">
                      <th style="width: 30%;">{{ $t('pump_install.installed_date') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.updated_at | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.district') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ schemeHierarchyNames.districtName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.union') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ schemeHierarchyNames.unionName }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.pump_jl_no') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.pump_mauza_no_bn : application.pump_mauza_no }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.project') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="6">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th>{{ ($i18n.locale == 'bn') ? application.project_name_bn : application.project_name }}</th>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <b-row class="mb-3" v-if="application.survey_id !== null">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.survey') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="6">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.survey_date') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.survey_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.suggestionl') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? application.suggestion_bn : application.suggestion }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <b-row v-if="survey_notes.length > 0">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.survey_note') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="12">
                  <b-table-simple striped bordered small>
                    <b-tr>
                      <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                      <b-th class="text-center">{{ $t('pump_install.note') }}</b-th>
                    </b-tr>
                    <b-tr v-for="(survey_note, index) in survey_notes" :key="index">
                      <b-td>{{ $n(index + 1) }}</b-td>
                      <b-td>{{ ($i18n.locale == 'bn') ? survey_note.note_bn : survey_note.note }}</b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row class="mb-3" v-if="application.license_id !== null">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.license') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="6">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.license_no') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.license_no }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.issue_date') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">{{ application.license_issue_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th style="width: 30%;">{{ $t('pump_install.attachment') }} :</th>
                      <td style="border-bottom: 1px dashed #999;">
                        <a :href="baseUrl + 'download-attachment?file=uploads/pump-installation/license/original/' + application.license_attachment" title="License">
                          <i class="ri-download-cloud-fill"></i>
                          <span> Download</span>
                        </a>
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
              <b-row v-if="progress_types.length > 0 && progress_types[0].far_pump_install.length > 0">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.pump_installation_progress') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="12">
                  <b-table-simple striped bordered small>
                    <b-tr>
                      <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                      <b-th>{{ $t('pump_install.step_name') }}</b-th>
                      <b-th>{{ $t('pump_install.start_date') }}</b-th>
                      <b-th>{{ $t('pump_install.end_date') }}</b-th>
                      <b-th>{{ $t('pump_install.notel') }}</b-th>
                    </b-tr>
                    <b-tr v-for="(progress_type, index) in progress_types" :key="index">
                      <b-td>{{ $n(index + 1) }}</b-td>
                      <b-td>{{ ($i18n.locale == 'bn') ? progress_type.step_name_bn : progress_type.step_name }}</b-td>
                      <b-td>{{ progress_type.far_pump_install[0] !== undefined ? progress_type.far_pump_install[0].start_date : '' }}</b-td>
                      <b-td>{{ progress_type.far_pump_install[0] !== undefined ? progress_type.far_pump_install[0].end_date : '' }}</b-td>
                      <b-td>{{ progress_type.far_pump_install[0] !== undefined ? ($i18n.locale == 'bn') ? progress_type.far_pump_install[0].note_bn : progress_type.far_pump_install[0].note : '' }}</b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row v-else>
          <b-col sm="12">
            <b-overlay :show="loader">
              <div class="text-dark text-center">
                Loading...
              </div>
          </b-overlay>
          </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { applicationShow } from '../api/routes'
import HierarchycalDropdownNames from '@/Utils/common'
import ExportPdf from './scheme_application_details_pdf'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getSchemeApplicationDetails()
      Object.freeze(tmp)
      this.application = tmp
    }
  },
  data () {
    return {
        loader: true,
        appHierarchyNames: null,
        pumpHierarchyNames: null,
        schemeHierarchyNames: null,
        application: '',
        survey_notes: [],
        progress_types: [],
        baseUrl: irriSchemeServiceBaseUrl
    }
  },
  computed: {
    applicationData () {
      return Object.assign({}, this.application, this.appHierarchyNames, this.pumpHierarchyNames, this.schemeHierarchyNames)
    }
  },
  methods: {
    async getSchemeApplicationDetails () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${applicationShow}/${this.$props.id}`)
      if (result.success) {
        this.application = this.getRelationalData(result)
        this.survey_notes = result.survey_notes
        this.progress_types = result.progress_types
        this.appHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.application.far_union_id, 'union')
        this.pumpHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.application.pump_union_id, 'union')
        this.schemeHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.application.sch_man_union_id, 'union')
      }
      this.loader = false
    },
    getRelationalData (result) {
      const organizationList = this.$store.state.orgList
        const orgObject = organizationList.find(oganization => oganization.value === result.data.org_id)
        const orgData = {
          org_name: orgObject !== undefined ? orgObject.text_en : '',
          org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
        }
      const pumpCapacityList = this.$store.state.IrriConfig.commonObj.pumpCapacityList
      const pumpCapacity = pumpCapacityList.find(capacity => capacity.value === result.data.pump_capacity_id)
      const pumpCapacityData = {
        pump_capacity: pumpCapacity !== undefined ? pumpCapacity.text_en : '',
        pump_capacity_bn: pumpCapacity !== undefined ? pumpCapacity.text_bn : ''
      }
      const surveyNoteData = {
        survey_notes: result.survey_notes
      }
      const progressTypesData = {
        progress_types: result.progress_types
      }
      return Object.assign({}, result.data, surveyNoteData, progressTypesData, pumpCapacityData, orgData)
    },
    getOrganization (orgId) {
        const orgList = this.$store.state.orgList
        const org = orgList.find(orgItem => orgItem.value === orgId)
        if (this.$i18n.locale === 'bn') {
          return org.text_bn
        } else {
          return org.text_en
        }
    },
    getSubSchemeType (subSchemeTypeId) {
      const subSchemeTypeList = this.$store.state.IrriConfig.commonObj.subSchemeTypeList
      const subScheme = subSchemeTypeList.find(sub => sub.value === subSchemeTypeId)
      if (this.$i18n.locale === 'bn') {
        return subScheme !== undefined ? subScheme.text_bn : ''
      } else {
        return subScheme !== undefined ? subScheme.text_en : ''
      }
    },
    getPumpCapacity (pumpCapacityId) {
      const pumpCapacityList = this.$store.state.IrriConfig.commonObj.pumpCapacityList
      const pumpCapacity = pumpCapacityList.find(capacity => capacity.value === pumpCapacityId)
      return pumpCapacity !== undefined ? pumpCapacity.text_en : ''
    },
    pdfExport () {
      const reportTitle = this.$t('pump_install.application_details')
      ExportPdf.exportPdfDetails(reportTitle, this, this.applicationData)
    },
    getPumpAddress (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.pump_mauza_no_bn, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_bn)
      } else {
        address.push(item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_en)
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    }
  }
}
</script>
